import { motion } from "framer-motion"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
const container = {
  out: { opacity: 0 },
  in: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15
    }
  }
}

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" description="Whoops, this page wasn't found!"/>
    <motion.div variants={container} initial="out" animate="in" className="error-container">
      <h1>404<br/>Not Found</h1>
      <h3>Sorry we couldn't<br/>find what you were looking for!</h3>
    </motion.div>
  </Layout>
)

export default NotFoundPage
